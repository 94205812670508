/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useSpring, animated } from 'react-spring';

import { themes } from '../helpers';

import logo from '../assets/images/logo-white.png';

const posMixin = css`
  position: absolute;
  top: 0;
  left: 0;
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 18px 18px 0 0;
  background: ${(props) => {
    if (props.expanded) {
      return props.theme.statusBarExpandedBg;
    }
    return props.theme.statusBarBg;
  }};

  display: flex;
  flex-shrink: 0;

  transition: height 0.5s ease;
  height: ${(props) => (props.expanded ? 'calc(100% - 71px)' : '80px')};
`;

const Icon = styled(({ expanded, ...props }) => <animated.div {...props} />)`
  ${posMixin}
  top: max(5%, 20px);
  left: 25px;

  transition: top 0.4s ease;
  ${(props) => !props.expanded && 'top: 15px;'}

  span {
    display: block;
    background-image: url(${logo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;
  }
`;

const Name = styled.div`
  ${posMixin};
  font-weight: bold;
  color: #ffffff;

  span {
    font-family: ${(props) => props.theme.fontFamily};
  }
`;

const Status = styled.div`
  position: absolute;
  top: 43px;
  left: 81px;
  overflow: hidden;

  span {
    font-family: ${(props) => props.theme.fontFamily};
    font-size: 16px;
    line-height: 19px;
    padding-left: 11px;
    color: rgba(255, 255, 255, 0.4);
    white-space: nowrap;
    position: relative;
    &:before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.statusDot};
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
`;

const TextBox = styled(({ expanded, ...props }) => <animated.div {...props} />)`
  position: absolute;
  top: max(100% - 177px, 180px);
  left: 25px;

  transition: top 0.25s ease;
  ${(props) => !props.expanded && 'top: 100%;'}
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #ffffff;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  font-family: ${(props) => props.theme.fontFamily};
`;

const SubTitle = styled.div`
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.282353px;
  color: #ffffff;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  font-family: ${(props) => props.theme.fontFamily};
  margin-top: 14px;
`;

const StatusBar = ({ expanded, botName }) => {
  const iconStyle = useSpring({
    width: expanded ? '80px' : '47px',
    height: expanded ? '80px' : '47px',
  });
  const nameStyle = useSpring({
    fontSize: expanded ? '22px' : '22px',
    lineHeight: expanded ? '26px' : '26px',
    transform: expanded ? 'translate(81px, -50px)' : 'translate(81px, 15px)',
  });
  const statusStyle = useSpring({
    transform: expanded ? 'translate(0, 100%)' : 'translate(0, 0%)',
  });

  return (
    <Wrapper expanded={expanded}>
      <Name>
        <animated.div style={nameStyle}>
          <span>{botName}</span>
        </animated.div>
      </Name>

      <Icon style={iconStyle} expanded={expanded}>
        <span />
      </Icon>

      <Status>
        <animated.div style={statusStyle}>
          <span>Онлайн</span>
        </animated.div>
      </Status>

      <TextBox expanded={expanded}>
        <Title>
          Приветствую Вас! <br /> Я виртуальный помощник Алсу.
        </Title>
        <SubTitle>
          Вы можете задать мне вопрос. На данный момент я работаю в тестовом режиме.
        </SubTitle>
      </TextBox>
    </Wrapper>
  );
};

Wrapper.defaultProps = {
  expanded: true,
  theme: themes.blue,
};

Wrapper.propTypes = {
  expanded: PropTypes.bool,
  theme: PropTypes.shape({}),
};

StatusBar.defaultProps = {
  expanded: true,
  botName: 'Алсу',
};

StatusBar.propTypes = {
  expanded: PropTypes.bool,
  botName: PropTypes.string,
};

export default StatusBar;
