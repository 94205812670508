import axios from 'axios';
import guid from 'uuid/v1';
import getCookie from '../helpers/utils/getCookie';

const useHttp = () => {
  const auth = async () => {
    const userIdCookie = getCookie('UserId');
    const userIdLocalStorage = localStorage.getItem('user_id');
    const newUserId = guid();

    const userId = userIdCookie || userIdLocalStorage || newUserId;

    if (!userIdLocalStorage) {
      localStorage.setItem('user_id', userId);
    }

    const data = JSON.stringify({
      user_id: userId,
      user_data: '',
      referrer: document.referrer,
      history_length: 20,
    });

    const backAddressHttp =
      process.env.REACT_APP_RENDER_DEMO === 'true'
        ? localStorage.getItem('backAddressHttp')
        : process.env.REACT_APP_HOST_HTTP;

    if (
      userIdCookie &&
      userIdLocalStorage &&
      userIdCookie !== userIdLocalStorage
    ) {
      try {
        await axios.post(`${backAddressHttp}/change_user_id`, {
          new_user_id: userIdCookie,
          old_user_id: userIdLocalStorage,
        });

        localStorage.setItem('user_id', userIdCookie);
      } catch (error) {
        console.error(error, '(changing user id)');
      }
    }

    return axios.post(`${backAddressHttp}/ws_auth`, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  };

  return {
    auth,
  };
};

export default useHttp;
