import React from 'react';
import styled from 'styled-components';

const Image = styled.img`
  width: 100%;
  margin-top: 4px;
  height: auto;
  border-radius: 3px;
`;

const ImageZoom = ({ zoom, src }) => {
  const zoomRef = React.useRef(
    zoom.clone({ background: 'black', scrollOffset: 200 }),
  );

  const attachZoom = (image) => {
    zoomRef.current.attach(image);
  };

  return (
    <Image
      src={src}
      ref={attachZoom}
      alt="Приложенная к сообщению фотография"
    />
  );
};

export default ImageZoom;
