import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import WidgetInit from './WidgetInit';
import TextField from './TextField';
import Chat from './Chat';

import { Button, Plate, StatusBar } from '../ui';
import { getPosition, selectors } from '../helpers';
import { useWidgetContext } from '../hooks';

const {
  positionSelector,
  messagesSelector,
  isButtonsActiveSelector,
  isTypingSelector,
} = selectors;

const Container = styled.div`
  font-family: ${(props) => props.theme.fontFamily};
  font-style: normal;
  font-weight: normal;
  position: fixed;
  z-index: 1000;
  box-sizing: content-box;

  > * {
    padding: 20px;
  }

  ${(props) => css([getPosition(props.position)])};
`;

const Content = styled.div`
  overflow: hidden;
  z-index: 1;

  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: stretch;
`;

const Footer = styled.div`
  border-radius: 0 0 18px 18px;
  overflow: hidden;
  position: relative;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  /* &:before {
    content: '';
    border-top: 1px solid #cecece;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  } */
`;

const WidgetShell = () => {
  const [active, toggleActive] = useState(false);
  const [expanded, toggleExpanded] = useState(true);

  const { store } = useWidgetContext();
  const position = positionSelector(store);
  const messages = messagesSelector(store);
  const typing = isTypingSelector(store)

  const isButtonsActive = isButtonsActiveSelector(store);

  return (
    <Container position={position}>
      <Button
        position={position}
        active={active}
        onClick={() => toggleActive((prevActive) => !prevActive)}
      />
      <Plate active={active} position={position}>
        <WidgetInit>
          <StatusBar expanded={expanded} />
          <Content>
            <Chat
              messages={messages}
              typing={typing}
              isButtonsActive={isButtonsActive}
              isWidgetActive={active}
            />
          </Content>
          <Footer>
            <TextField toggle={(state) => toggleExpanded(state)} />
          </Footer>
        </WidgetInit>
      </Plate>
    </Container>
  );
};

export default WidgetShell;
