import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  chatButton as ChatIcon,
  closeButton as CloseIcon,
} from '../assets/icons';
import { themes } from '../helpers';

const ButtonBox = styled.div`
  width: 60px;
  height: 60px;

  border-radius: 50%;
  background: ${(props) => props.theme.buttonBg};
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;

const Popup = styled.div`
  position: absolute;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  font-family: ${(props) => props.theme.fontFamily};

  white-space: nowrap;
  padding: 5px 18px;
  background-color: #404040;

  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5.5px 0 5.5px 18px;
    border-color: transparent transparent transparent #404040;
    position: absolute;
  }

  ${(props) => {
    switch (props.position) {
      case 'top-left':
        return css`
          top: 50%;
          left: 100%;
          transform: translate3d(10px, 10px, 0);
          border-radius: 0 7px 7px 7px;

          &:before {
            bottom: 100%;
            right: 100%;
            transform: translate(8px, 6px) rotate(-145deg);
          }
        `;
      case 'top-right':
        return css`
          top: 50%;
          right: 100%;
          transform: translate3d(-10px, 10px, 0);
          border-radius: 7px 0 7px 7px;

          &:before {
            bottom: 100%;
            left: 100%;
            transform: translate(-8px, 5px) rotate(-45deg);
          }
        `;
      case 'bottom-left':
        return css`
          bottom: 50%;
          left: 100%;
          transform: translate3d(10px, -10px, 0);
          border-radius: 7px 7px 7px 0;

          &:before {
            top: 100%;
            right: 100%;
            transform: translate(7px, -5px) rotate(145deg);
          }
        `;
      case 'bottom-right':
        return css`
          bottom: 50%;
          right: 100%;
          transform: translate3d(-10px, -10px, 0);
          border-radius: 7px 7px 0 7px;

          &:before {
            top: 100%;
            left: 100%;
            transform: translate(-8px, -4px) rotate(45deg);
          }
        `;
      default:
        return css``;
    }
  }};
`;

const Wrapper = styled.div`
  box-sizing: content-box;
  width: 60px;
  height: 60px;

  position: relative;

  ${(props) =>
    ['top-left', 'top-right'].includes(props.position)
      ? css`
          padding-bottom: 0 !important;
        `
      : css`
          padding-top: 0 !important;
        `}

  ${(props) =>
    ['top-left', 'bottom-left'].includes(props.position)
      ? css`
          padding-right: 0 !important;
        `
      : css`
          padding-left: 0 !important;
        `}

  ${(props) =>
    !props.active &&
    css`
      &:hover {
        ${Popup} {
          opacity: 1;
          visibility: visible;
        }
      }
    `}
`;

const ButtonIcon = styled.div`
  @keyframes fade-scale-in {
    0% {
      opacity: 0;
      transform: rotate(360deg) scale(0);
    }
    100% {
      opacity: 1;
      transform: rotate(0deg) scale(1);
    }
  }

  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    opacity: 0;
    transform: rotate(360deg) scale(0);
    animation: fade-scale-in 0.3s forwards;
  }
`;

const Button = ({ position, active, onClick }) => {
  const [clicked, updateClicked] = useState(false);

  return (
    <Wrapper active={active} position={position}>
      <ButtonBox
        role="button"
        onClick={() => {
          updateClicked(true);
          onClick();
        }}
      >
        <ButtonIcon>{active ? <ChatIcon /> : <CloseIcon />}</ButtonIcon>
      </ButtonBox>
      {process.env.REACT_APP_SHOW_POPUP === 'true' && !clicked && (
        <Popup position={position}>{process.env.REACT_APP_POPUP_MSG}</Popup>
      )}
    </Wrapper>
  );
};

Wrapper.defaultProps = {
  theme: themes.blue,
};

Button.defaultProps = {
  onClick: () => {},
  position: 'bottom-right',
  active: false,
};

Button.propTypes = {
  onClick: PropTypes.func,
  position: PropTypes.oneOf([
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
  ]),
  active: PropTypes.bool,
};

export default Button;
