import React from 'react';
import styled from 'styled-components';

import { useSocketContext, useWidgetContext } from '../hooks';
import { selectors } from '../helpers';

const { buttonsSelector, chatIdSelector } = selectors;

const Wrapper = styled.div`
  padding-top: 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  margin-left: -10px;
  margin-bottom: -15px;
`;

const Button = styled.button`
  cursor: pointer;

  min-width: calc(${(props) => 100 / props.btnSize}% - 6px);

  border: 1.5px solid ${(props) => props.theme.botMsgColor};
  color: ${(props) => props.theme.botMsgColor};
  background-color: #fff;
  border-radius: 10px;

  line-height: 18px;
  letter-spacing: 0.282353px;
  font-family: ${(props) => props.theme.fontFamily};
  font-style: light;
  font-weight: 400;
  font-size: 15px;

  display: flex;
  flex: 1 0;
  justify-content: center;
  box-sizing: border-box;

  padding: 5px;
  margin: 3px;
  outline: none;

  &:active {
    background-color: ${(props) => props.theme.botMsgColor};
    color: #fff;
  }
`;

const buttonClick = ({ value, toggleButtons, sendButton }) => {
  sendButton(value);
  toggleButtons(false);
};

const ButtonBar = () => {
  const { store, toggleButtons, addMessage, saveButtons } = useWidgetContext();
  const { sendButton } = useSocketContext();
  const buttons = buttonsSelector(store);
  const chatId = chatIdSelector(store);
  return (
    <Wrapper>
      {buttons.map(({ text, id, size }) => (
        <Button
          btnSize={size}
          key={`btn-${id}`}
          onClick={() => {
            buttonClick({
              value: text,
              addMessage,
              chatId,
              toggleButtons,
              saveButtons,
              sendButton,
            });
          }}
        >
          {text}
        </Button>
      ))}
    </Wrapper>
  );
};

export default ButtonBar;
