import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import './App.css';
import { Widget } from './widget';
import { positions, themes as themesObj } from './widget/helpers';

const Canvas = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  #back-address {
    display: flex;
    flex-direction: column;

    & label {
      display: flex;

      & span {
        margin-right: 5px;
      }

      & > * {
        display: flex;
        flex: 1 0;
      }
    }
  }
`;

// only for debug!

function App({ theme: currentTheme }) {
  const themes = { currentTheme, ...themesObj };
  const [actualTheme, updateTheme] = useState(Object.keys(themes)[0]);
  const [position, updatePosition] = useState(positions[3]);
  const [backAddressHttp, updateBackAddressHttp] = useState('');
  const [backAddressWs, updateBackAddressWs] = useState('');

  localStorage.setItem('user_data', '{"name": "LocalUser"}');

  useEffect(() => {
    if (localStorage.getItem('backAddressHttp') === null) {
      localStorage.setItem('backAddressHttp', process.env.REACT_APP_HOST_HTTP);
    }
    if (localStorage.getItem('backAddressWs') === null) {
      localStorage.setItem('backAddressWs', process.env.REACT_APP_HOST_WS);
    }
    updateBackAddressHttp(localStorage.getItem('backAddressHttp'));
    updateBackAddressWs(localStorage.getItem('backAddressWs'));
  }, []);

  return (
    <Canvas>
      <form id="back-address">
        <label htmlFor="back-addr-http">
          <span>Backend HTTP</span>
          <input
            id="back-addr-http"
            type="text"
            value={backAddressHttp}
            onChange={(e) => updateBackAddressHttp(e.target.value)}
          />
        </label>

        <label htmlFor="back-addr-ws">
          <span>Backend WS</span>
          <input
            type="text"
            id="back-addr-ws"
            value={backAddressWs}
            onChange={(e) => updateBackAddressWs(e.target.value)}
          />
        </label>

        <button
          onClick={() => {
            localStorage.setItem('backAddressHttp', backAddressHttp);
            localStorage.setItem('backAddressWs', backAddressWs);
            localStorage.removeItem('user_id');
          }}
        >
          update
        </button>
      </form>
      <div>
        <ul>
          {positions.map((pos, idx) => (
            <li key={`pos-${idx}`}>
              <input
                id={`pos-${idx}`}
                name="position"
                type="radio"
                checked={position === pos}
                onChange={() => updatePosition(pos)}
              />
              <label htmlFor={`pos-${idx}`}>{pos}</label>
            </li>
          ))}
        </ul>
        <ul>
          {Object.keys(themes).map((theme, idx) => (
            <li key={`theme-${idx}`}>
              <input
                id={`theme-${idx}`}
                name="themes"
                type="radio"
                checked={actualTheme === theme}
                onChange={() => updateTheme(theme)}
              />
              <label htmlFor={`theme-${idx}`}>{theme}</label>
            </li>
          ))}
        </ul>
      </div>
      <Widget theme={themes[actualTheme]} position={position} />
    </Canvas>
  );
}

App.defaultProps = {
  theme: themesObj.blue,
};

App.propTypes = {
  theme: PropTypes.shape({
    buttonBg: PropTypes.string,
    statusBarExpandedBg: PropTypes.string,
    statusBarBg: PropTypes.string,
    statusDot: PropTypes.string,
    loader: PropTypes.string,
    bubleColor: PropTypes.object
  }),
};

export default App;
