import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg``;

export default () => (
  <SVG width="30" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="0"
        width="30"
        height="27"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation=".5" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
    <g filter="url(#filter0_d)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 2h-8a8 8 0 100 16h8a8 8 0 100-16zm10 8c0-5.523-4.477-10-10-10h-8C5.477 0 1 4.477 1 10s4.477 10 10 10h4.805l1.287 1 3.15 2.446 2 1.554v-5.537C26.172 18.116 29 14.388 29 10z"
        fill="#fff"
      />
    </g>
  </SVG>
);
