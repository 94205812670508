const positions = ['top-left', 'top-right', 'bottom-left', 'bottom-right'];

const getPosition = (position) => {
  switch (position) {
    case 'top-left':
      return 'top: 0; left: 0; transform: translate(0, 0)';
    case 'top-right':
      return 'top: 0; left: 100%; transform: translate(-100%, 0)';
    case 'bottom-left':
      return 'top: 100%; left: 0; transform: translate(0, -100%)';
    case 'bottom-right':
      return 'top: 100%; left: 100%; transform: translate(-100%, -100%)';
    default:
      return '';
  }
};

const getDirection = (position) => {
  switch (position) {
    case 'top-left':
      return 'top: 100%; left: 0px';
    case 'top-right':
      return 'top: 100%; right: 0px;';
    case 'bottom-left':
      return 'bottom: 100%; left: 0px';
    case 'bottom-right':
      return 'bottom: 100%; right: 0px';
    default:
      return '';
  }
};

const getTransition = (position) => {
  switch (position) {
    case 'top-left':
      return {
        from: { transform: 'translate3d(0, 20px ,0)' },
        enter: { transform: 'translate3d(0, 0, 0)' },
        leave: { transform: 'translate3d(0, 20px ,0)' },
      };
    case 'top-right':
      return {
        from: { transform: 'translate3d(0, 20px ,0)' },
        enter: { transform: 'translate3d(0, 0, 0)' },
        leave: { transform: 'translate3d(0, 20px ,0)' },
      };
    case 'bottom-left':
      return {
        from: { transform: 'translate3d(0, -20px ,0)' },
        enter: { transform: 'translate3d(0, 0, 0)' },
        leave: { transform: 'translate3d(0, -20px ,0)' },
      };
    case 'bottom-right':
      return {
        from: { transform: 'translate3d(0, -20px ,0)' },
        enter: { transform: 'translate3d(0, 0, 0)' },
        leave: { transform: 'translate3d(0, -20px ,0)' },
      };
    default:
      return '';
  }
};

export { positions, getPosition, getDirection, getTransition };
