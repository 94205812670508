/* eslint-disable no-nested-ternary */
import React, { useReducer, createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { merge } from 'lodash';
import { createSelector } from 'reselect';

export const WidgetContext = createContext({});

const reducer = (state, { type, payload }) => {
  switch (type) {
    case 'updateAppState':
      return merge({}, state, {
        appState: payload,
      });
    case 'saveChatHistory':
      return merge({}, state, {
        chatState: {
          messages: payload,
        },
      });
    case 'addMessage': {
      return {
        ...state,
        chatState: {
          ...state.chatState,
          messages: state.chatState.messages
            .slice(
              state.chatState.messages.length - state.chatState.maxMsgAmount,
            )
            .concat(payload),
          typing: {
            ...state.chatState.typing,
            isTyping:
              payload.author.type === 'operator'
                ? false
                : state.chatState.typing.isTyping,
          },
        },
      };
    }
    case 'operatorTyping': {
      return {
        ...state,
        chatState: {
          ...state.chatState,
          typing: {
            name: payload.is_typing ? payload.name : '',
            isTyping: payload.is_typing,
          },
        },
      };
    }

    case 'updateMsgStatus': {
      const msgList = state.chatState.messages;
      const index = msgList.indexOf(
        msgList.filter((el) => el.id === payload.id)[0],
      );
      return merge({}, state, {
        chatState: {
          messages: Object.assign([], msgList, {
            [index]: {
              ...msgList[index],
              status: payload.status ? 'received' : 'failed'
            },
          }),
        },
      });
    }
    case 'unshiftMessage':
      return merge({}, state, {
        chatState: {
          messages: [...payload, ...state.chatState.messages],
        },
      });
    case 'updatePosition':
      return merge({}, state, {
        widgetState: {
          position: payload,
        },
      });
    case 'setUserId':
      return merge({}, state, {
        chatState: {
          userId: payload,
        },
      });
    case 'setChatId':
      return merge({}, state, {
        chatState: {
          chatId: payload,
        },
      });
    case 'toggleButtons':
      return merge({}, state, {
        widgetState: {
          isButtonsActive: payload,
        },
      });
    case 'saveButtons':
      return { ...state, chatState: { ...state.chatState, buttons: payload } };

    default:
      throw new Error();
  }
};

const WidgetProvider = ({ position, children }) => {
  const [store, dispatch] = useReducer(reducer, {
    appState: {
      loaded: false,
      success: false,
    },
    chatState: {
      messages: [],
      chatId: '',
      userId: '',
      buttons: [],
      maxMsgAmount: 40,
      typing: { isTyping: false },
    },
    widgetState: {
      position,
      isButtonsActive: false,
    },
  });

  useEffect(() => {
    dispatch({
      type: 'updatePosition',
      payload: position,
    });
  }, [position]);

  return (
    <WidgetContext.Provider
      value={{
        store,
        updateAppState: (payload) => {
          dispatch({
            type: 'updateAppState',
            payload,
          });
        },
        saveButtons: (payload) => {
          const buttons = payload.map((btn, index) => {
            const maxBtnWordLen = Math.max(
              ...btn.title.split(/\s+/).map((w) => w.length),
            );
            const btnTextLen = Math.max(btn.title.length / 2, maxBtnWordLen);
            return {
              id: index,
              text: btn.title,
              size:
                btnTextLen >= 16
                  ? 1
                  : btnTextLen > 10
                    ? 2
                    : btnTextLen > 6
                      ? 3
                      : btnTextLen > 4
                        ? 4
                        : 5,
            };
          });
          dispatch({
            type: 'saveButtons',
            payload: buttons,
          });
        },
        setUserId: (payload) => {
          dispatch({
            type: 'setUserId',
            payload,
          });
        },
        setChatId: (payload) => {
          dispatch({
            type: 'setChatId',
            payload,
          });
        },
        saveChatHistory: (payload) => {
          dispatch({
            type: 'saveChatHistory',
            payload,
          });
        },
        addMessage: (payload) => {
          dispatch({
            type: 'addMessage',
            payload,
          });
        },
        updateMsgStatus: (payload) => {
          dispatch({
            type: 'updateMsgStatus',
            payload,
          });
        },
        unshiftMessage: (payload) => {
          dispatch({
            type: 'unshiftMessage',
            payload,
          });
        },
        toggleButtons: (payload) => {
          dispatch({
            type: 'toggleButtons',
            payload,
          });
        },
        operatorTyping: (payload) => {
          dispatch({
            type: 'operatorTyping',
            payload,
          });
        },
      }}
    >
      {children}
    </WidgetContext.Provider>
  );
};

const appStateSelector = createSelector(
  (store) => store.appState,
  (appState) => appState,
);
const positionSelector = createSelector(
  (store) => store.widgetState.position,
  (position) => position,
);
const messagesSelector = createSelector(
  (store) => store.chatState.messages,
  (messages) => messages,
);
const chatIdSelector = createSelector(
  (store) => store.chatState.chatId,
  (chatId) => chatId,
);
const userIdSelector = createSelector(
  (store) => store.chatState.userId,
  (userId) => userId,
);
const isButtonsActiveSelector = createSelector(
  (store) => store.widgetState.isButtonsActive,
  (isButtonsActive) => isButtonsActive,
);
const buttonsSelector = createSelector(
  (store) => store.chatState.buttons,
  (buttons) => buttons,
);
const isTypingSelector = createSelector(
  (store) => store.chatState.typing,
  (typing) => typing,
);

export const selectors = {
  appStateSelector,
  positionSelector,
  messagesSelector,
  chatIdSelector,
  userIdSelector,
  isButtonsActiveSelector,
  buttonsSelector,
  isTypingSelector,
};

WidgetProvider.defaultProps = {
  children: {},
  position: 'bottom-right',
};

WidgetProvider.propTypes = {
  children: PropTypes.element,
  position: PropTypes.string,
};

export default WidgetProvider;
