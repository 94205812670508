import '@babel/polyfill';
import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Widget } from './widget';
import { themes } from './widget/helpers/utils';

const createTheme = (theme, color1, color2) => ({

  ...theme,
  buttonBg: color2
    ? `linear-gradient(135deg, ${color1} 0%, ${color2} 100%);`
    : color1,
  statusBarExpandedBg: color2
    ? `linear-gradient(124.19deg, ${color1} 0%, ${color2} 100%);`
    : color1,
  statusBarBg: color2
    ? `linear-gradient(167.47deg, ${color1} 0%, ${color2} 100%);`
    : color1,
  usrMsgColor: 'white',
  botMsgColor: color2 || color1,
  statusDot: '#95FAC9',
  loader: color2 || color1,
  fontFamily: process.env.REACT_APP_FONT,
});

const themeName = process.env.REACT_APP_WIDGET_THEME;
let theme = themes.blue;

if (themeName)
  if (themeName.indexOf('~') !== -1) {
    // 2 colors
    theme = createTheme(theme, ...themeName.split('~'));
  } else if (themeName.indexOf('#') !== -1) {
    // 1 color
    theme = createTheme(theme, themeName);
  } else {
    // theme name
    theme = themes[themeName];
  }

console.log(theme)
if (process.env.REACT_APP_RENDER_DEMO === 'true') {
  ReactDOM.render(<App theme={theme} />, document.getElementById('root'));
} else {
  const position = process.env.REACT_APP_WIDGET_POSITION || 'bottom-right';
  ReactDOM.render(
    <Widget theme={theme} position={position} />,
    document.getElementById('root'),
  );
}
