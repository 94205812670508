import { format } from 'date-fns';

const timeDelta = 86400000;

const getTimeWord = (time) => {
  const chatDate = format(new Date(time), 'dd.MM.yyyy');
  const today = format(new Date(), 'dd.MM.yyyy');
  const yesterday = format(new Date() - timeDelta, 'dd.MM.yyyy');
  const dayBefYesterday = format(new Date() - timeDelta * 2, 'dd.MM.yyyy');

  if (today === chatDate) {
    return format(new Date(time), 'HH:mm');
  }

  if (yesterday === chatDate) {
    return 'Вчера';
  }

  if (dayBefYesterday === chatDate) {
    return 'Позавчера';
  }

  return format(new Date(time), 'dd.MM.yy');
};

export default getTimeWord;
