import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Loader, Error } from '../ui';
import { useWidgetContext, useHttp, useSocketContext } from '../hooks';
import { includeTimezone } from '../helpers/utils';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const WidgetInit = ({ children }) => {
  const {
    store: { appState },
    updateAppState,
    saveChatHistory,
    saveButtons,
    toggleButtons,
  } = useWidgetContext();
  const { connect } = useSocketContext();
  const { auth } = useHttp();

  useEffect(() => {
    if (!appState.loaded) {
      auth()
        .then((res) => {
          updateAppState({
            loaded: true,
            success: true,
          });

          saveChatHistory(
            res.data.chat_history.map((msg) => ({
              ...msg,
              datetime: includeTimezone(msg.datetime),
            })),
          );

          if (res.data.buttons) {
            saveButtons(res.data.buttons);
            toggleButtons(true);
          }

          connect(res.data.ws_url);
        })
        .catch(() => {
          updateAppState({
            loaded: true,
            success: false,
          });
        });
    }
  }, []);

  if (appState.loaded && appState.success) {
    return <>{children}</>;
  }

  if (appState.loaded && !appState.success) {
    return (
      <Wrapper>
        <Error />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Loader />
    </Wrapper>
  );
};

WidgetInit.defaultProps = {
  children: {},
};

WidgetInit.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
};

export default WidgetInit;
