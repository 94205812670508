import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SimpleBar from 'simplebar-react';
import { throttle } from 'lodash';
import InfiniteScroll from 'react-infinite-scroller';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import ButtonBar from './ButtonBar';
import { Message, Typing } from '../ui';
import { useSocketContext } from '../hooks';
import { isMobile } from '../helpers/utils';
import '../assets/css/simplebar.css';

const ChatWrapper = styled.div`
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  height: 100%;
  align-items: stretch;
`;

const ChatMessageList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 25px 20px;
  min-height: 400px;
`;

const ChatFiller = styled.div`
  flex-grow: 1;

  & + .onechat-date-label {
    margin-top: 0;
  }
`;

const fireOnTop = throttle(
  (fn, setFiredOnTop) => {
    setFiredOnTop(true);
    fn();
    setTimeout(() => {
      setFiredOnTop(false);
    }, 500);
  },
  500,
  {
    trailing: false,
  },
);

const Chat = ({ messages, typing, isButtonsActive, isWidgetActive }) => {
  const scrollboxRef = useRef();
  const messageListRef = useRef();
  const [firedOnTop, setFiredOnTop] = useState(false);
  const {
    getMessages,
    connected,
    noMessagesLeft,
    messagesCounter,
  } = useSocketContext();

  useEffect(() => {
    if (isMobile()) {
      if (isWidgetActive) {
        disableBodyScroll(scrollboxRef.current);
      } else {
        enableBodyScroll(scrollboxRef.current);
      }
    }
  }, [isWidgetActive]);

  useEffect(() => {
    if (!firedOnTop && scrollboxRef.current !== null) {
      if (messagesCounter === 0) {
        const timerId = setInterval(() => {
          scrollboxRef.current.scrollTop = scrollboxRef.current.scrollHeight;
        }, 100);
        setTimeout(() => {
          clearInterval(timerId);
        }, 500);
      }

      const lastMsg = messages[messages.length - 1];

      if (lastMsg !== undefined) {
        if (lastMsg.author.type === 'user') {
          scrollboxRef.current.scrollTop = scrollboxRef.current.scrollHeight;
        } else {
          const timerId = setInterval(() => {
            const msgList = messageListRef.current.children;
            const btnsPanelHeight = msgList[msgList.length - 1].offsetHeight;
            const lastMagHeight = msgList[msgList.length - 2].offsetHeight;
            scrollboxRef.current.scrollTop =
              scrollboxRef.current.scrollHeight -
              btnsPanelHeight -
              lastMagHeight -
              20;
          }, 100);

          setTimeout(() => {
            clearInterval(timerId);
          }, 500);
        }
      }
    }
  }, [messagesCounter]);

  return (
    <ChatWrapper>
      <SimpleBar
        autoHide={false}
        scrollableNodeProps={{ ref: scrollboxRef }}
        style={{ width: '100%' }}
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={() => {
            fireOnTop(() => {
              getMessages(messages.length, 10);
            }, setFiredOnTop);
          }}
          initialLoad={false}
          useWindow={false}
          hasMore={connected && !noMessagesLeft}
          threshold={150}
          getScrollParent={() => scrollboxRef.current}
          isReverse
        >
          <ChatMessageList ref={messageListRef}>
            <ChatFiller />
            {messages.map((message) => (
              <Message key={`msg-${message.datetime}`} message={message} />
            ))}
            {typing.isTyping && <Typing operatorName={typing.name} /> }
            {/* {messages.map((msg) => (
            <Fragment key={`message-${nanoid()}`}>
              <DateLabel key={`date-${nanoid()}`}>{getTime(msg.datetime)}</DateLabel>
              {messages[time].map((message) => (
                <Message key={`msg-${nanoid()}`} message={message} />
              ))}
            </Fragment>
          ))} */}
            {isButtonsActive && <ButtonBar />}
          </ChatMessageList>
        </InfiniteScroll>
      </SimpleBar>
    </ChatWrapper>
  );
};

Chat.defaultProps = {
  messages: [],
  isButtonsActive: false,
  isWidgetActive: false,
  typing: {
    isTyping: false,
    name: null,
  },
};

Chat.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      author: PropTypes.shape({ type: PropTypes.string }),
    }),
  ),
  isButtonsActive: PropTypes.bool,
  isWidgetActive: PropTypes.bool,
  typing: PropTypes.shape({
    isTyping: PropTypes.bool,
    name: PropTypes.string,
  }),
};

export default Chat;
