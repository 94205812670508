import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg``;

export default () => (
  <SVG width="22" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d)" fill="#fff">
      <rect
        width="1.404"
        height="26.677"
        rx=".702"
        transform="matrix(.71223 -.70195 .71223 .70195 1 .985)"
      />
      <rect
        width="1.404"
        height="26.677"
        rx=".702"
        transform="scale(1.00724 .9927) rotate(45 9.577 24.114)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x=".293"
        y=".289"
        width="21.414"
        height="21.423"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation=".5" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
    </defs>
  </SVG>
);
