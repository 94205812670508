import React, { createContext } from 'react';
import guid from 'uuid/v1';
import PropTypes from 'prop-types';
import { merge, get, has } from 'lodash';
import UIfx from 'uifx';

import { WidgetContext } from './WidgetProvider';
import { includeTimezone, toBase64 } from '../utils';
import { useHttp } from '../../hooks';
const { auth } = useHttp();

export const SocketContext = createContext({});

const getPacket = (id, method, data) => {
  const msg = {
    method,
    id,
  };
  if (
    method === 'getMessages' ||
    method === 'connect' ||
    method === 'reconnect'
  ) {
    msg.args = data;
  } else if (method === 'sendMessage' || method === 'sendButton') {
    msg.text = data;
  } else if (method === 'userTyping') {
    msg.args = data
  }
  else {
    console.log('Invalid method. Socket Provider: getPacket.');
  }
  return JSON.stringify(msg);
};

class SocketProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      socket: null,
      connected: false,
      noMessagesLeft: false,
      messagesCounter: 0,
    };

    this.connect = this.connect.bind(this);

    this.getMessages = this.getMessages.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.sendButton = this.sendButton.bind(this);
    this.sendUserTyping = this.sendUserTyping.bind(this);
    this.saveMessageHistory = this.saveMessageHistory.bind(this);

    this.getMessagesRequests = {};
    this.sendMessageRequests = {};
  }

  getMessages(offset, limit) {
    const id = guid();
    this.getMessagesRequests[id] = { offset, limit };
    this.ws.send(getPacket(id, 'getMessages', { offset, limit }));
  }

  connect(url, reconnect = false, onConnectCallback = () => { }) {
    const backAddressWs =
      process.env.REACT_APP_RENDER_DEMO === 'true'
        ? localStorage.getItem('backAddressWs')
        : process.env.REACT_APP_HOST_WS;

    this.ws = new WebSocket(`${backAddressWs}${url}`);

    this.ws.onopen = () => {
      console.log('socket open');
      this.setState({
        connected: true,
      });
      const serviceId = guid();
      if (reconnect) {
        this.ws.send(getPacket(serviceId, 'reconnect', {}));
      } else {
        this.ws.send(getPacket(serviceId, 'connect', {}));
      }
      onConnectCallback();
    };
    this.ws.onclose = () => {
      console.log('socket close');
      this.setState({
        connected: false,
      });
    };
    this.ws.onmessage = (e) => {
      const message = JSON.parse(e.data);
      const {
        addMessage,
        unshiftMessage,
        toggleButtons,
        saveButtons,
        updateMsgStatus,
        operatorTyping,
      } = this.context;

      if (message.type === 'event') {
        if (message.event === 'newMessage') {
          addMessage({
            ...message.data,
            datetime: new Date(message.data.datetime).getTime(),
          });
          if (message.data.author.type === 'operator') {
            const sound_object = new UIfx(`${process.env.PUBLIC_URL}/sounds/operator_sound_message.mp3`)
            sound_object.play();
            return;
          }
          toggleButtons(false);
          if (message.data.buttons) {
            toggleButtons(true);
            saveButtons(message.data.buttons);
          }
        }
        else if (message.event === 'operatorTyping') {
          operatorTyping(message.data);
        }
      } else if (message.type === 'response') {
        if (message.id in this.getMessagesRequests) {
          // const { offset } = this.getMessagesRequests[message.id];
          delete this.getMessagesRequests[message.id];
          // this.saveMessageHistory(offset, message.data.chat_history);
          this.setState({
            noMessagesLeft: message.data.chat_history.length === 0,
          });

          unshiftMessage(
            message.data.chat_history.map((msg) => ({
              ...msg,
              datetime: includeTimezone(msg.datetime),
            })),
          );
        } else if (message.id in this.sendMessageRequests) {
          delete this.sendMessageRequests[message.id];
          updateMsgStatus({ id: message.id, status: message.data.success });
          this.setState((prevState) => ({
            messagesCounter: prevState.messagesCounter + 1,
          }));
        } else {
          console.log(message);
        }
      } else {
        console.log(message);
      }
    };
    this.ws.onerror = (e) => console.log('websocket error', e.data);

    this.setState({
      socket: this.ws,
    });
  }

  sendMessage(message) {
    const { addMessage } = this.context;
    const id = guid();
    this.sendMessageRequests[id] = {
      id,
      datetime: new Date().getTime(),
      text: message,
      author: { type: 'user' },
      status: 'sending',
    };
    addMessage(this.sendMessageRequests[id]);
    if (this.ws.readyState === this.ws.OPEN) {
      this.ws.send(getPacket(id, 'sendMessage', message));
    } else {
      auth().then((res) => {
        this.connect(res.data.ws_url, true, () => {
          this.ws.send(getPacket(id, 'sendMessage', message));
        });
      });
    }

    this.setState({
      noMessagesLeft: false,
    });
  }

  sendButton(message) {
    const { addMessage } = this.context;
    const id = guid();
    this.sendMessageRequests[id] = {
      id,
      datetime: new Date().getTime(),
      text: message,
      author: { type: 'user' },
      status: 'sending',
    };
    addMessage(this.sendMessageRequests[id]);
    if (this.ws.readyState === this.ws.OPEN) {
      this.ws.send(getPacket(id, 'sendButton', message));
    } else {
      auth().then((res) => {
        this.connect(res.data.ws_url, true, () => {
          this.ws.send(getPacket(id, 'sendButton', message));
        });
      });
    }

    this.setState({
      noMessagesLeft: false,
    });
  }

  sendUserTyping(isTyping) {
    const id = guid();
    if (this.ws.readyState === this.ws.OPEN) {
      this.ws.send(getPacket(id, 'userTyping', { is_typing: isTyping }));
      return true;
    }
    return false;
  }

  saveMessageHistory(offset, data) {
    const { saveChatHistory } = this.context;

    const messages = data.reduce((obj, message) => {
      const datetime = new Date(message.datetime);
      const time = new Date(
        `${datetime.getMonth() +
        1} ${datetime.getDate()} ${datetime.getFullYear()}`,
      ).getTime();

      if (!has(obj, time)) {
        return merge({}, obj, {
          [time]: [message],
        });
      }
      return merge({}, obj, {
        [time]: get(obj, time, []).concat(message),
      });
    }, {});

    saveChatHistory({
      messages,
      count: data.length,
    });
  }

  render() {
    const { children } = this.props;
    const { socket, connected, noMessagesLeft, messagesCounter } = this.state;
    return (
      <SocketContext.Provider
        value={{
          socket,
          connected,
          noMessagesLeft,
          messagesCounter,
          connect: this.connect,
          getMessages: this.getMessages,
          sendMessage: this.sendMessage,
          sendButton: this.sendButton,
          sendUserTyping: this.sendUserTyping,
          saveMessageHistory: this.saveMessageHistory,
        }}
      >
        {children}
      </SocketContext.Provider>
    );
  }
}

SocketProvider.contextType = WidgetContext;

SocketProvider.defaultProps = {
  children: {},
};

SocketProvider.propTypes = {
  children: PropTypes.element,
};

export default SocketProvider;
