import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  user-select: none;

  margin: 15px 0;
`;

const Label = styled.div`
  height: 25px;
  line-height: 25px;
  padding: 0 10px;

  color: rgba(29, 38, 41, 0.3);

  background-color: #efefef;
  border-radius: 10px;

  font-family: ${(props) => props.theme.fontFamily};
`;

const DateLabel = ({ children }) => (
  <Wrapper className="onechat-date-label">
    <Label>{children}</Label>
  </Wrapper>
);

DateLabel.defaultProps = {
  children: '',
};

DateLabel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DateLabel;
