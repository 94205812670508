import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import mediumZoom from 'medium-zoom';
import replace from 'string-replace-to-array';
import ImageZoom from './ImageZoom';

import { getTimeWord } from '../helpers/utils';

const Container = styled.div`
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  user-select: none;

  /* opacity: 0;
  animation: fade-in 0.3s forwards; */

  & + & {
    margin-top: 15px;
  }
`;

const Wrapper = styled.div`
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  user-select: none;

  /* opacity: 0;
  animation: fade-in 0.3s forwards; */
`;

const Bubble = styled.div`
  max-width: 230px;
  box-sizing: border-box;
  padding: 9px 12px 10px 13px;
  border-radius: 10px;

  background-color: ${(props) =>
    props.theme.bubleColor[props.author.type].bg || props.theme.botMsgColor};
`;

const Text = styled.div`
  white-space: pre-line;
  user-select: text;
  word-wrap: break-word;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.3px;
  font-family: ${(props) => props.theme.fontFamily};

  a {
    word-break: normal;
    color: ${(props) =>
      props.theme.bubleColor[props.author.type].link ||
      props.theme.botMsgColor};
  }

  color: ${(props) =>
    props.theme.bubleColor[props.author.type].text || props.theme.botMsgColor}

  };
`;

const TextTyping = styled.div`
  white-space: pre-line;
  user-select: text;
  word-wrap: break-word;
  font-size: 16px;
  font-style: italic;
  line-height: 21px;
  font-family: ${(props) => props.theme.fontFamily};
  color: #ffffff;
`;

const Time = styled.div`
  color: ${(props) => (props.status === 'failed' ? 'rgba(188, 14, 14)' : 'rgba(29, 38, 41, 0.3)')};
  padding-left: 10px;

  font-weight: ${(props) => (props.value === '!' ? '700' : '300')};
  font-size: 12px;
  line-height: 14px;
  font-family: ${(props) => props.theme.fontFamily};
`;

const TimeStatusValue = ({ status, datetime, isUser }) => {
  const [value, setValue] = useState();
  useEffect(() => {
    if (!isUser) {
      setValue(getTimeWord(datetime));
    } else if (status === 'sending' && isUser) {
      setValue('отправка...');
    } else if (status === 'failed' && isUser) {
      setValue('!')
    } else if (isUser) {
      setValue('Доставлено');
      setTimeout(() => {
        setValue(getTimeWord(datetime));
      }, 3000);
    }
  }, [status]);

  return (
    <Time
      onMouseEnter={() => {
        if (status === 'received' && isUser) {
          setValue('Доставлено');
        } else if (status === 'failed' && isUser) {
          setValue('не доставлено');
        }
      }}
      onMouseLeave={() => {
        if (status === 'received' && isUser) {
          setValue(getTimeWord(datetime));
        } else if (status === 'failed' && isUser) {
          setValue('!');
        }
      }}
      status={status}
      value={value}
    >
      {value}
    </Time>
  );
};

const LinkButton = styled.button`
  word-break: break-all;
  background: none !important;
  border: none;
  outline: none;
  padding: 0 !important;
  font-family: inherit;
  font-size: inherit;
  color: ${(props) => props.theme.botMsgColor};
  text-decoration: underline;
  cursor: pointer;
`;

// ingores case when there's a double quote at the end
const urlRegex = /(?!(https?):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?")(https?):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g;
const linksRegex = /<a.*?href=\\?"(.*?)\\?".*?>(.*?)<\/a>/g;
const boldRegex = /<b>(.*?)<\/b>/g;

function Message({
  message: { text, author, attachments, datetime, status },
  sendMessage,
}) {
  let msgText = text;
  if (text !== '') {
    msgText = replace(msgText, /\\n/g, (_, offset) => <br key={offset} />);
    msgText = replace(msgText, urlRegex, (match, offset) => (
      <a key={offset} href={match} target="_blank" rel="noreferrer noopener">
        {match}
      </a>
    ));

    if (author.type !== 'user') {
      msgText = replace(msgText, linksRegex, (match, url, title, offset) =>
        url === 'scenario' ? (
          <LinkButton
            key={offset}
            onClick={() => {
              sendMessage(title);
            }}
          >
            {title}
          </LinkButton>
        ) : (
            <a key={offset} href={url} target="_blank" rel="noreferrer noopener">
              {title}
            </a>
          ),
      );
      msgText = replace(msgText, boldRegex, (match, offset) => (
        <b>{offset}</b>
      ));
    }
  }

  const zoom = React.useRef(mediumZoom());
  const image =
    attachments &&
    attachments.length > 0 &&
    ['png', 'jpg', 'jpeg', 'gif'].includes(attachments[0].type);
  return (
    ((image || text) &&
    <Container>
      <Wrapper data-selector-id="chat-message">
        <Bubble author={author}>
          {text && <Text author={author}>{msgText}</Text>}
          {image && <ImageZoom src={attachments[0].link} zoom={zoom.current} />}
        </Bubble>
        <TimeStatusValue
          status={status}
          datetime={datetime}
          isUser={author.type === 'user'}
        />
      </Wrapper>
    </Container>
    )
  );
}

const DotsMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WaveAnimation = keyframes`
  0%, 60%, 100% {
    transform: initial;
  }
  30% {
    transform: translateY(-4px);
  }
`;

const Dot = styled.div`
  display: inline-block;
  animation: ${WaveAnimation} 1s ease-in-out infinite;
  animation-delay: ${(props) => props.delay};
`;

function ThreeDotsBox({ operatorName }) {
  return (
    <DotsMessageWrapper>
      <TextTyping>
        {` Оператор ${operatorName || ''} печатает`}
        <Dot delay="0s">.</Dot>
        <Dot delay="-0.9s">.</Dot>
        <Dot delay="-0.8s">.</Dot>
      </TextTyping>
    </DotsMessageWrapper>
  );
}

function Typing({ operatorName }) {
  return (
    <Container>
      <Wrapper data-selector-id="chat-message">
        <Bubble author={{ type: 'operator' }}>
          <ThreeDotsBox operatorName={operatorName} />
        </Bubble>
      </Wrapper>
    </Container>
  );
}

Message.defaultProps = {
  message: {},
  sendMessage: () => { },
};

Message.propTypes = {
  message: PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    datetime: PropTypes.number,
    author: PropTypes.shape({
      type: PropTypes.string,
    }),
    status: PropTypes.string,
    attachments: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        link: PropTypes.string,
      }),
    ),
  }),
  sendMessage: PropTypes.func,
};

Typing.propTypes = {
  operatorName: PropTypes.string.isRequired,
};

TextTyping.propTypes = {
  operatorName: PropTypes.string.isRequired,
};

ThreeDotsBox.propTypes = {
  operatorName: PropTypes.string.isRequired,
};

export { Message, Typing };
TimeStatusValue.propTypes = {
  status: PropTypes.string.isRequired,
  isUser: PropTypes.bool.isRequired,
  datetime: PropTypes.number.isRequired,
};

export default Message;
