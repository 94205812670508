import React from 'react';
import styled from 'styled-components';

import confused from '../assets/images/confused.png';

const Box = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.div`
  background-image: url(${confused});
  background-size: contain;
  width: 48px;
  height: 48px;
`;

const Text = styled.div`
  color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  margin-top: 12px;
`;

const Error = () => (
  <Box>
    <Icon />
    <Text>Кажется что-то пошло не так...</Text>
  </Box>
);

export default Error;
