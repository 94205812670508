import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';

import { send as SendIcon } from '../assets/icons';
import { useSocketContext } from '../hooks';

const Wrapper = styled.div`
  display: flex;

  height: 100%;
  width: 100%;
  border-top: 1px solid #cecece;
  box-sizing: border-box;
  min-height: 70px;
  max-height: 200px;
`;

const Textarea = styled(TextareaAutosize)`
  max-height: 100%;
  width: 100%;

  resize: none;
  border: none;
  margin: 0;
  padding: 25px 0 25px 25px;

  font-size: 16px;
  line-height: 20px;
  font-family: ${(props) => props.theme.fontFamily};
  font-style: normal;
  font-weight: normal;

  scrollbar-width: none;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  overflow-y: scroll;

  box-sizing: border-box;

  transition: height 0.2s ease;

  &::placeholder {
    color: rgba(29, 38, 41, 0.3);
    transition: color 0.2s;
  }

  &:focus {
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SendBox = styled.div`
  display: flex;
  flex-grow: 0;
`;

const Send = styled.button`
  border: none;
  background: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;

  & svg path {
    fill: ${(props) => props.theme.botMsgColor};
  }

  &:hover {
    & {
      transform: scale(1.15);
    }
  }

  & {
    & {
      transition: transform 0.2s;
    }
  }

  &:focus,
  &:active {
    outline: none;
  }
`;

const TextField = ({ toggle, placeholder }) => {
  const [value, update] = useState('');
  const { sendMessage, sendUserTyping } = useSocketContext();
  const textAreaRef = useRef();
  const [isTyping, setIsTyping] = useState('');
  const firstTimeRender = useRef(true);

  const submitMsg = (e) => {
    if (value.trim()) {
      sendMessage(value);
    }
    update('');
    sendUserTyping(false);
    e.preventDefault();
  };

  useEffect(() => {
    if (!firstTimeRender.current) {
      if (!value) {
        sendUserTyping(false);
        setIsTyping(false);
      } else if (!isTyping) {
        const sendSuccess = sendUserTyping(true);
        if (sendSuccess) {
          setIsTyping(true);
        }
      }
    }
  }, [value]);

  useEffect(() => {
    firstTimeRender.current = false;
  }, []);

  return (
    <Wrapper>
      <Textarea
        inputRef={textAreaRef}
        placeholder={placeholder}
        value={value}
        onFocus={() => {
          toggle(false);
        }}
        onChange={(e) => {
          update(e.target.value);
        }}
        onKeyPress={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            submitMsg(e);
          }
        }}
        onHeightChange={() => {
          textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
        }}
      />

      <SendBox>
        <Send onClick={submitMsg}>
          <SendIcon />
        </Send>
      </SendBox>
    </Wrapper>
  );
};

TextField.defaultProps = {
  toggle: () => { },
  placeholder: 'Напишите нам',
};

TextField.propTypes = {
  toggle: PropTypes.func,
  placeholder: PropTypes.string,
};

export default TextField;
