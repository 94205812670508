const blue = {
  buttonBg: 'linear-gradient(135deg, #2e408d 0%, #79c6e9 100%)',
  statusBarExpandedBg: 'linear-gradient(124.19deg, #2e408d 0%, #79c6e9 100%)',
  statusBarBg: 'linear-gradient(152.96deg, #2E408D -6.03%, #79C6E9 76.31%);',
  statusDot: '#95FAC9',
  usrMsgColor: 'white',
  botMsgColor: '#79c6e9',
  loader: '#79C6E9',
  fontFamily: process.env.REACT_APP_FONT,
  bubleColor: {
    user: { bg: '#79c6e9', text: '#fff', link: '#3498DB' },
    operator: { bg: '#4cbf5d', text: '#fff', link: '#3498DB' },
    bot: { bg: '#d8d9db', text: '#000', link: '#3498DB' },
  },
};

const green = {
  buttonBg: 'linear-gradient(135deg, #9ED33C 0%, #65A81C 100%);',
  statusBarExpandedBg: 'linear-gradient(124.19deg, #9ED33C 0%, #65A81C 100%);',
  statusBarBg: 'linear-gradient(167.47deg, #9ED33C 0%, #65A81C 100%);',
  statusDot: '#95FAC9',
  usrMsgColor: 'white',
  botMsgColor: '#65A81C',
  loader: '#65A81C',
  fontFamily: process.env.REACT_APP_FONT,
};

export default {
  blue,
  green,
};
