import React from 'react';
import PropTypes from 'prop-types';
import { animated, useTransition } from 'react-spring';
import { merge } from 'lodash';
import styled, { css } from 'styled-components';

import { getTransition, getDirection } from '../helpers';

const Wrapper = styled(animated.div)`
  max-height: 640px;
  max-width: 400px;
  width: 100vw;
  height: calc(100vh - 100%);
  box-sizing: border-box;

  position: absolute;
  ${(props) => css([getDirection(props.position)])};
`;

const Box = styled.div`
  width: 100%;
  height: 100%;

  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
  border-radius: 18px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`;

const Plate = ({ active, position, children }) => {
  const transitions = useTransition(
    active,
    null,
    merge(
      {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
      },
      getTransition(position),
    ),
  );

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <Wrapper key={key} style={props} position={position}>
          <Box>{children}</Box>
        </Wrapper>
      ),
  );
};

Plate.defaultProps = {
  position: 'bottom-right',
  active: false,
  children: {},
};

Plate.propTypes = {
  position: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.element,
};

export default Plate;
