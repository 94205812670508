import React from 'react';
import styled from 'styled-components';

const SVG = styled.svg``;

export default () => (
  <SVG xmlns="http://www.w3.org/2000/svg" width="22" height="22">
    <path
      fill="#79C6E9"
      fillRule="evenodd"
      d="M21.407718.00025718C21.402832 0 21.398203 0 21.393574 0c-.01466 0-.029319.00051436-.044235.00154307-.070724.00514357-.138105.02237451-.200085.0496354L.374136 8.65662075c-.249463.10338566-.400427.35927805-.370337.62777217.030347.26849412.234547.48452387.500726.52953007 2.918717.49635402 5.578712 1.87585832 7.691945 3.98934942 2.113492 2.1132339 3.492996 4.7732288 3.98935 7.691945.045006.2661795.261036.4703791.52953.5007261.022632.0023146.04552.0036005.068152.0036005.242776 0 .464978-.1458201.55962-.3739372L21.945735.85666083C21.980712.7802789 22 .69541006 22 .6061692c0-.3302169-.263865-.59871102-.592282-.60591201"
    />
  </SVG>
);
