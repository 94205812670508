import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import { WidgetShell } from './components';
import { WidgetProvider, SocketProvider, themes } from './helpers';

const Widget = ({ theme, position }) => (
  <ThemeProvider theme={theme}>
    <WidgetProvider position={position}>
      <SocketProvider>
        <WidgetShell />
      </SocketProvider>
    </WidgetProvider>
  </ThemeProvider>
);

Widget.defaultProps = {
  theme: themes.blue,
  position: 'bottom-right',
};

Widget.propTypes = {
  theme: PropTypes.shape({
    buttonBg: PropTypes.string,
    statusBarExpandedBg: PropTypes.string,
    statusBarBg: PropTypes.string,
    statusDot: PropTypes.string,
    loader: PropTypes.string,
    bubleColor: PropTypes.shape
  }),
  position: PropTypes.oneOf([
    'top-left',
    'top-right',
    'bottom-left',
    'bottom-right',
  ]),
};

export default Widget;
